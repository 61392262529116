.infobar-container {
  background-image: linear-gradient(to top right, #0edbee, #740eff);
  color     : white;
  z-index   : 15;
  position  : fixed;
  transition: 1s;
  box-shadow: 0 -1px 20px #808080;

  .header-container {
    display         : flex;
    justify-content : space-between;
    z-index         : 15;

    .close-btn {
      margin-right: 10px;
      background  : transparent;
      color       : white;
      border      : none;

      img {
        height: 18px;
        width: 18px;
      }
    }

    h5 {
      margin    : 11px 10px 10px;
      font-size : 20px;
    }
  }

  .infobar-social {
    img {
      height: 30px;

      &.instagram-like {
        position  : absolute;
        top       : -21px;
        animation : bounce 1.5s;
        animation-iteration-count: infinite;
      }
    }
  }

  .contact-btn {
    border    : 2px white solid;
    display   : block;
    text-align: center;
    color     : white;
    text-decoration: none;
    font-size: 20px;
    letter-spacing: 1px;
    padding: 8px 0px 6px;
  }


  &.mobile,
  &.tablet {

    h5 {
      font-size: 23px;
      -webkit-font-smoothing: antialiased;
      letter-spacing: 1.5px;
    }

    .header-container {
      justify-content: space-between;
      align-items: center;

      .close-btn {
        margin-right: 24px;
      }
    }

    .infobar-content {

      .infobar-contact {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .contact-container {
          margin: 10px 0;
          text-align: center;

          .contact-item {
            flex-basis: 100%;

            .phone-number,
            .coordinates {
              color: white;
            }
          }
        }
      }

      .separator {
        height          : 0;
        background-color: white;
      }
    }

    .infobar-social {
      display         : flex;
      margin          : 40px 0;
      justify-content : space-between;
      position        : relative;
    }
  }

  &.mobile {
    bottom: 0;
    width: 100%;
    z-index: 20;

    .header-container {
      height: 40px;

      .close-btn {
        margin-right: 30px;
      }
    }

    h5 {
      margin: -1px 0 0 30px;
    }

    &.opened {
      animation: slide-up-info-bar .5s linear forwards;
    }

    &.closed {
      animation: slide-down-info-bar .5s linear forwards;
    }

    .infobar-content {
      font-size: 16px;
      margin: 10px 30px;

      .infobar-contact {
        font-size: 13.75px;
        margin-top: 41px;
        margin-bottom: 37px;

        .contact-container {
          line-height: 22px;
          margin: 3px;

          &:nth-of-type(1) {
          }
        }

        .contact-item.name {
          font-size: 17.5px;
        }
      }

      .separator {
        margin-bottom: 45px;
      }

      .contact-btn {
        font-size: 16px;
        letter-spacing: 0.75px;
        padding: 5px 0px 3px;
      }

      .infobar-social {
        margin-bottom: 38px;
      }
    }
  }

  &.tablet {
    height: 58px;
    line-height: 2.5;

    bottom: 0;
    width: 100%;
    z-index: 20;

    h5 {
      margin: -1px 0 0 28px;
    }

    .header-container {
      height: 68px;
    }

    &.opened {
      animation: slide-up-info-bar-tablet .5s linear forwards;
    }

    &.closed {
      animation: slide-down-info-bar-tablet .5s linear forwards;
    }

    .infobar-content {
      margin    : 10px 0;
      width     : 64%;
      margin    : auto;
      font-size : 20px;

      .infobar-contact {
        font-size: 16px;

        .contact-container {
          font-size: 16px;
          letter-spacing: 1px;
          margin: 17px 0 4px;

          .contact-item {
            line-height: 26px;
            -webkit-font-smoothing: antialiased;

            &.name {
              font-size: 20px;
              letter-spacing: 1px;
            }
            &.email {
              letter-spacing: 0;
            }
          }
        }

      }

      .contact-btn {
        padding-bottom: 10px;
        line-height: 26px;
      }
    }

    .infobar-social {
      width : 30%;
      margin: 32px auto;

      img {
        height: 30px;

        &.instagram-like {
          top: -8px;
        }
      }
    }
  }

  &.web {
    bottom          : 0;
    right           : 0;
    width           : 10%;
    height          : 124px;
    display         : flex;
    justify-content : center;
    align-items     : center;
    box-shadow      : none;

    &:hover {
      transition: 1s .7s;
      box-shadow: 0 -1px 20px #808080;
    }

    .web-wrapper {
      height: 124px;
      background-image: linear-gradient(to top right, #0edbee, #740eff);
      color: white;
      position: absolute;
      width: 100%;

      .header-container {
        position: absolute;
        z-index: 20;

        h5 {
          margin: 40px 0 10px 50px;
        }
      }

      &.open > .web-infobar-content {
        animation: slide-left-info-bar 1s linear forwards;
      }

      &.closed > .web-infobar-content {
        animation: slide-right-info-bar 1s linear forwards;
      }
    }
  }

  .web-infobar-content {
    position: absolute;
    color   : white;
    height  : 124px;
    width   : 900%;
    right   : -800%;
    background-image: linear-gradient(to top right, #0edbee, #740eff);
    display         : flex;
    flex-direction  : row;
    align-items     : center;
    justify-content : flex-end;
    z-index: -1;
    box-shadow: 0 -1px 20px #00000080;
    padding: 0 55px;

    .infobar-contact {
      display       : flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: auto;

      .web-separator {
        height          : 0;
        width           : 50px;
        background-color: white;
        transform       : rotate(90deg);
        margin          : 26px 20px 0;
      }

      .contact-item {

        &.name {
          font-size: 20px;
          letter-spacing: 1px;
        }

        .phone-number,
        .coordinates {
          color: white;
          -webkit-font-smoothing: antialiased;
          letter-spacing: 0.9px;
        }
      }
    }

    .infobar-social {
      display         : flex;
      flex-basis      : 20%;
      justify-content : space-between;
      position        : relative;
      margin-right: 55px;

      .instagram-group {
        position: relative;

        .instagram-like {
          left: 0;
        }
      }
    }

    .contact-btn {
      flex-basis: 20%;
      margin    : 3px 0;
      cursor    : pointer;
    }
  }
}

@keyframes slide-up-info-bar {
  from {
    height: 40px;
    padding-top: 0;
  }
  to {
    height: 375px;
    padding-top: 15px;
  }
}

@keyframes slide-down-info-bar {
  from {
    height: 375px;
    padding-top: 15px;
  }
  to {
    height: 40px;
    padding-top: 0;
  }
}

@keyframes slide-up-info-bar-tablet {
  from { height: 60px }
  to { height: 400px }
}

@keyframes slide-down-info-bar-tablet {
  from { height: 400px }
  to { height: 60px }
}

@keyframes slide-left-info-bar {
  from { right: -800% }
  to { right: 100% }
}

@keyframes slide-right-info-bar {
  from { right: 100% }
  to { right: -800% }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
  35% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  65% {
    transform: translateY(0);
  }
}
