.search-bar-container {
  width   : 100%;
  position: fixed;
  z-index : 101;
  top     : 0;
  transition: 1s;

  &.mobile {
    .search-bar-input {
      height: 38px;
    }

    .search-bar-icon {
      height  : 15px;
      top: calc(50% - 7.5px);
      right: 17px;
    }
  }

  &.tablet {
    .search-bar-input {
      height: 50px;
      width: 101%;
      margin-left: -1px;
    }

    .search-bar-icon {
      top     : 10px;
      height  : 35px;
    }
  }

  .search-bar-input {
    width: 100%;
    border-radius: 0;
    border: 0;
    height: 58px;
  }

  .search-bar-icon {
    position: absolute;
    right   : 27px;
    top     : calc(50% - 11px);
    height  : 26px;
  }
}

.web-search-bar {
    position: absolute;
    width   : 150px;
    height  : 30px;
    top     : 56px;
    right   : 54px;
    z-index : 101;

    .web-search-input {
      background: transparent;
      border    : none;
      width     : 130px;
      color     : white;
      outline   : none;
    }

    .web-search-image {
      position  : absolute;
      top       : 0;
      right     : 0;
      height    : 100%;
      z-index   : -1;
      transition: 1s;
    }
}
