body {
  margin: auto;
  font-family: 'Ahamono-regular', serif;

  .app-container {

    .go-to-top {
      position        : absolute;
      color           : black;
      top             : 166vh;
      right           : calc(3% - 39px);
      transform       : rotate(-90deg);
      text-decoration : none;
      background      : transparent;
      border          : none;
      z-index         : 10;
      top: 22.5vw;
      outline: none;
      -webkit-font-smoothing: antialiased;

      &.start {
        position: fixed;
      }

      .lines {
        letter-spacing: -1px;
      }
    }

    &.mobile {
      .projects-list {
        margin-bottom: 50px;
      }
    }

    &.tablet {
      .projects-list {
        margin-bottom: 65px;
      }
    }

    &.web {
      .go-to-top {
        font-size: 12px;
      }

      .projects-list {
        margin-bottom: 0;
      }
    }
  }
}
