.project-container {
  // margin-bottom: 15px;
  -webkit-font-smoothing: antialiased;

  .img-container {

    &.no-effect {
      img {
        filter: opacity(1);
      }
    }

    img {
      width: 100%;
      filter: opacity(0.63);
    }
  }

  .accordion {
    position: relative;

    .expand-button {
      position: absolute;
      top     : 100%;
      z-index : 1;
    }

    .card {
      border: none;

      .card-header {
        padding       : 0;
        border-bottom : none;
        background    : white;
      }

      .card-body {
        padding: 0;
        overflow: hidden;

        .collapse {
          padding-bottom: 87px;
        }
      }

      .bullet-point-icon {
        height      : 20px;
        width       : auto;
        margin-right: 12px;
      }
    }

    .header-container {
      position: relative;

      .work-order-container {
        position  : absolute;
        top       : 40%;
        text-align: center;

        .work,
        .work-order {
          padding-left: 10px;
          display     : flex;

          .leading-index {
            height: 1em;
            display: inline-block;
          }

          .full-index {
            display   : flex;
            width     : 10px;
            overflow-x: hidden;

            .old-index {
              transition: 1s;

              &.hide {
                margin-left: -10px;
              }
            }
          }
        }

        .work-order-separator {
          margin: 0;
          width: 50px;
        }
      }

      .project-header {
        position: relative;
        width: 80%;
        margin: auto;

        // &:hover > .header-text > .title-highlight {
        //   width: 90%;
        // }

        .header-text {
          position        : absolute;
          background-color: rgba(255,255,255,0.9);
          bottom          : 0;
          left            : 1rem;
          right           : 100px;
          padding         : 0 34px;

          .coming-soon-container {
            background-color: #FFF700;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: rotate(10deg);
            position: absolute;
            text-align: center;
            line-height: 19px;

            span {
              text-transform: uppercase;
            }
          }

          .header-title {
            position: relative;

            .title {
              position: relative;
              margin-top     : -25px;
              color   : white;
              z-index : 2;
              font-size: 25px;
              -webkit-font-smoothing: antialiased;
              text-transform: uppercase;
            }

            .title-highlight {
              background-image: linear-gradient(50deg, #00DAED, #5430FB 80%, #6C00FF);
              height          : calc(100% - 20px);
              width           : 100%;
              position        : absolute;
              top             : 10px;
              z-index         : 1;
              margin-left     : -34px;
              transition      : 1s;
            }
          }

          .description {
            font-size: 12px;
            -webkit-font-smoothing: antialiased;
            margin-top: 15px;
          }
        }
      }
    }

    .tags-section {
      height          : 88px;
      text-align      : right;
      background-color: white;
      padding-right   : 10%;
      padding-top: calc(13vw / 17);;
      font-size: 12px;
      font-size: calc(12vw / 17);;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      direction: rtl;
      margin-left: 57%;

      span {
        font-size: 12px;
        text-transform: lowercase;
      }
    }

    .project-middle-section {
      position  : relative;
      padding   : 0 34px;
      font-size : 20px;
      width: 100%;

      .date {
        width: fit-content;
        font-size: 20px;
        text-transform: uppercase;
        border-bottom: 1px solid currentColor; /* Or whatever color you want */
        text-decoration: none;
        -webkit-font-smoothing: antialiased;
        padding-bottom: 4px;
        margin-bottom: 8px;
      }

      .project-list {
        padding-left: 0;

        .project-item {
          margin-bottom: 3px;
          display: flex;
          flex-direction: row;

          &:first-child {
            margin-top: 5px;
          }

          img {
            margin-top: 3px;
          }
        }
      }
    }

    .design {
      z-index: 1;
      .design-highlight {
        .highlight {
          background-color: #63FF4D;
          z-index         : -1;
        }
      }
    }

    .description-section {
      .text {
        padding         : 2rem 2rem 0 2rem;
        font-size       : 14px;
        position        : relative;
      }

      .section-header {
        text-transform: uppercase;
        width: max-content;
        padding-bottom: 6px;
        border-bottom: solid 1px black;
        margin-bottom: 6px;
      }

      .solutions-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
          &:before {
            font-family: 'Monaco';
            font-size: 14px;
            content: '·';
            margin-right: 8px;
            margin-left: -16px;
          }
        }
      }

      .recognition {
        display: flex;
        padding: 24px 0 0 18px;

        .recognition-img {
          margin-left: -20px;
          margin-top: -9px;
        }

        .recognition-list {
          list-style: none;
          padding: 0;
        }
      }

      .go-to-top {
        right: -20px;
      }

      .carousel-container {
        padding-bottom: 100px;
        position: relative;

        figure {
          margin: 0;
        }
      }
    }

    .go-to-top {
      position        : absolute;
      color           : black;
      top             : 50%;
      right           : -15%;
      transform       : rotate(-90deg);
      text-decoration : none;
      background      : transparent;
      border          : none;

      .lines {
        letter-spacing: -1px;
      }
    }
  }

  &.mobile {
    margin-bottom: 45px;

    &:last-child {
      margin-bottom: 48px;
    }

    .accordion {

      .card {
        overflow: inherit;

        .card-body {
          .collapse {
            padding-bottom: 0;
          }
        }
      }

      .expand-button {
        right: 29%;
      }

      .project-header {

        .header-text {
          left: 6%;
          width: 65%;
          padding: 0 9px;

          &.coming-soon {
            height: 40%;
          }

          .coming-soon-container {
            height: 55px;
            width: 55px;
            border-radius: 27.5px;
            right: calc(-27.5px + 10px);
            bottom: calc(50% - 27.5px - 8px);

            span {
              font-size: 11.9px;
              letter-spacing: 0.6px;
              line-height: 11.9px;
            }
          }

          .header-title {
            .title {
              font-size: 25px;
              margin-top : -15px;
              line-height: calc(25px * 0.9);
            }

            .title-highlight {
              margin-left: -9px;
              top: 10px;
              height: calc(100% - 6px);
            }
          }

          .description {
            font-size: 14px;
            margin-top: 0;
          }
        }
      }

      .project-middle-section {
        position  : relative;
        padding   : 0 calc(5% + 15px);
        font-size : 11.5px;
        width: 100%;
        height: inherit;
        margin-top: 49px;

        .date {
          font-size: 11.5px;
          padding-bottom: 2px;
          padding-top: 1px;
          margin-bottom: 6px;
        }

        .project-list {
          padding-left: 0;

          .project-item {
            margin-bottom: 2px;

            .bullet-point-icon {
              height: 12px;
              margin-right: 6px;
            }

            &:first-child {
              margin-top: 5px;
            }
          }
        }
      }
    }

    .header-container {
      .project-header {
        width: 100%;
      }
    }

    .design {
      // position: absolute;
      // left    : 70%;
      // right   : 10%;
      // top     : 40%;
      // z-index : 2;
      font-size: 11.5px;
      margin-top: 8px;
    }

    .design-highlight {
      height          : 10px;
      z-index         : 1;
      position        : relative;
      // display         : block;

      .highlight {
        position: absolute;
        display : inline-block;
        width: calc(100% + 6px);
        height: 7px;
        bottom: -1px;
        left: -3px;
      }
    }

    .tags-section {
      text-align: left;
      padding-right: 0;
      font-size: 9.5px;
      padding-top: 11px;
      padding-bottom: 39px;
      height: auto;
      background-color: transparent;
      line-height: 12.5px;
      direction: ltr;
    }

    .go-to-top {
      background-color: black;
      color           : white;
      height          : 2rem;
      display         : flex;
      justify-content : center;
      align-items     : center;
      text-decoration : none;
      border          : none;
      width           : 100%;
      right: 0;
      transform: rotate(0);
      position: relative;
    }
  }

  &.tablet {
    .accordion {
      .expand-button {
        left: 45%;
      }

      .card {
        .bullet-point-icon {
          height: 12px;
        }
        .card-body {
          .collapse {
            padding-bottom: 64px;
          }
        }
      }

      .tags-section {
        padding-right: 10%;
        padding-top: 8px;
        height: 65px;
        margin-left: 63%
      }

      .project-header {
        position: relative;

        .header-text {
          left  : 10%;
          width : 35%;
          height: 85%;
          padding: 0 16px;

          &.coming-soon {
            height: 40%;
          }

          .coming-soon-container {
            height: 60px;
            width: 60px;
            border-radius: 30px;
            right: calc(-30px + 10px);
            bottom: 20%;

            span {
              font-size: 13.23px;
              letter-spacing: 0.67px;
              line-height: 13.23px;
            }
          }

          .header-title {

            .title {
              font-size : 38px;
              margin-top : -26px;
              line-height: calc(38px * 0.9);
            }

            .title-highlight {
              height: calc(100% - 12px);
              top: 17px;
              margin-left: -16px;
            }
          }

          .description {
            font-size : 16px;
            margin-top: 13px;
          }
        }
      }

      .project-middle-section {
        position  : absolute;
        bottom    : 6%;
        font-size : 13.3px;
        left      : 0;
        padding   : 0 25px;
        line-height: 19px;

        .date {
          font-size: 13.3px;
          padding-bottom: 3px;
          margin-bottom: 6px;
        }

        .project-list {

          .project-item {
            margin-bottom: 2px;
          }
        }
      }

      .design {
        position      : absolute;
        bottom        : 0;
        font-size     : 11px;
        margin-bottom : 3px;

        .design-highlight {
          position    : relative;
          width       : 110%;

          .highlight {
            position: absolute;
            display : inline-block;
            width   : 110%;
            height  : 8px;
            bottom  : -1px;
            left    : 5px;
          }
        }
      }

      .description-section {
        .text {
          width           : 80%;
          margin          : auto;
          padding         : 55px 0;
          display         : flex;
          flex-wrap       : wrap;
          justify-content : space-between;

          .left-side {
            flex-basis: 50%;

            h5 {
              -webkit-font-smoothing: antialiased;
              font-size: 23px;
              letter-spacing: 0;
              line-height: 1.4;
              margin-bottom: -24px;
            }

            h6 {
              -webkit-font-smoothing: antialiased;
              font-size: 13.3px;
              letter-spacing: 0.75px;
              margin-top: 23px;
            }

            p {
              -webkit-font-smoothing: antialiased;
              font-size: 11px;
              line-height: 17.43px;
              margin-bottom: 13px;
            }
          }

          .right-side {
            flex-basis: 40%;
            margin-top: 20px;

            h6 {
              -webkit-font-smoothing: antialiased;
              font-size: 13.3px;
              letter-spacing: 0.75px;
              margin-top: -15px;
            }

            p {
              -webkit-font-smoothing: antialiased;
              font-size: 11px;
              line-height: 17.43px;
              margin-bottom: 13px;
            }

            .design-section {

            }

            .data-section {
              ul {
                li {
                  font-size: 11px;
                  -webkit-font-smoothing: antialiased;
                  line-height: 18px;

                  &:before {
                    font-size: 11px;
                  }
                }
              }

              .recognition {
                padding: 14px 0 0 18px;
              }
            }


          }
        }
      }
    }
  }


  &.web {

    &:last-child {
      .accordion .tags-section {
        height: calc(44vw / 17);
      }
    }


    .accordion {
      .expand-button {
        left : 42%;
      }

      .header-container {
        .work-order-container {
          .work,
          .work-order {
            padding-left: 50px;
          }

          .work {
            &.transparent {
              .item {
                &.o{
                  animation: letter-fade 3s linear 1s forwards;
                }
                &.r{
                  animation: letter-fade 3s linear .5s forwards;
                }
                &.k{
                  animation: letter-fade 3s linear forwards;
                }
              }
            }
          }

          .work-order-separator {
            width: 100px;
          }
        }

        .project-header {
          position: relative;

          .header-text {
            left  : 7%;
            width : 35%;
            height: 84%;

            &.coming-soon {
              height: 30%;
            }

            .coming-soon-container {
              height: 86px;
              width: 86px;
              border-radius: 43px;
              right: -36px;
              bottom: calc(100% - 86px - 20px);

              span {
                font-size: 18.74px;
                letter-spacing: 0.94px;
                line-height: 18.74px;
              }
            }

            .header-title {
              .title {
                font-size : 64px;
                margin-top : calc(-46vw / 17);
                line-height: calc(64px * 0.9);
                font-size: 3.75vw;
                line-height: calc(3.75vw * 0.9);
              }

              .title-highlight {
                height: calc(100% - 24px);
                top: 29px;
                top: 1.7vw;
                height: calc(100% - 1.4vw);
              }
            }

            .description {
              font-size : 21px;
              margin-top: 15px;
              font-size: 1.22vw;
            }
          }
        }
      }

      .project-middle-section {
        position  : absolute;
        bottom    : 13.3%;
        // font-size : 16px;
        left      : 0;

        .date {
          font-size: 1.6vw;
        }

        .project-list {
          font-size: 1.17vw;
        }
      }

      .design {
        position      : absolute;
        bottom        : 0;
        font-size     : 14px;
        margin-bottom : 1px;
        font-size: 0.81vw;

        .design-highlight {
          position    : relative;
          width       : 110%;

          .highlight {
            position: absolute;
            display : inline-block;
            width   : calc(100% + 7px);
            height  : 8.9px;
            bottom  : -2.8px;
            left    : -3px;
          }
        }
      }

      .tags-section {

        span {

          font-size: 0.7vw;
        }
      }

      .description-section {

        .text {
          padding         : 89px 10% 51px;
          display         : flex;
          flex-wrap       : wrap;
          justify-content : space-between;

          .left-side {
            flex-basis: 45%;

            p {
              font-size: 14px;
              line-height: 22px;
              margin-bottom: 30px;
            }
          }

          .right-side {
            flex-basis      : 45%;
            display         : flex;
            justify-content : space-between;
            padding-top     : 16px;

            .design-section {
              flex-basis: 50%;

              p {
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 20px;
              }
            }

            .data-section {
              flex-basis: 40%;

              ul {
                li {
                  font-size: 14px;
                  line-height: 20px;
                  margin-bottom: 2px;
                }
              }

              .recognition {
                ul {
                  li {
                    font-size: 14px;
                    line-height: 18px;
                    margin-bottom: 2px;
                    letter-spacing: 0.5px;
                  }
                }
              }
            }
          }

          h5 {
            font-size: 36px;
            margin-bottom: 2px;
            line-height: 53px;
            letter-spacing: 0.25px;
          }

          h6 {
            font-size: 20px;
            text-decoration: none;
            border-bottom: 1px solid black;
            padding-bottom: 8px;
            margin-bottom: 13px;
            width: fit-content;
            letter-spacing: 1px;
          }
        }

        .carousel.slide {
          flex-basis: 50%;
          margin    : auto;
        }
      }
    }
  }
}

@keyframes letter-fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0
  }
}
