// FONTS //

@font-face {
  -webkit-font-smoothing: antialiased;
  font-family: "main-light";
  src: url("../font/Type-36-Light.ttf");
  font-weight: 100;
}

@font-face {
  -webkit-font-smoothing: antialiased;
  font-family: "main-thin";
  src: url("../font/Type-36-Thin.ttf");
  font-weight: 300;
}

@font-face {
  -webkit-font-smoothing: antialiased;
  font-family: "main-bold";
  src: url("../font/Type-36-Regular.ttf");
  font-weight: 900;
}

@font-face {
  -webkit-font-smoothing: antialiased;
  font-family: "section";
  src: url("../font/Section-Black.otf");
  font-weight: 900;
}

@font-face {
  -webkit-font-smoothing: antialiased;
  font-family: 'Ahamono-regular';
  src: url('../font/AHAMONO-Regular/Desktop/AHAMONO-Regular.ttf'),
  url('../font/AHAMONO-Regular/Desktop/AHAMONO-Regular.otf'),
  url('../font/AHAMONO-Regular/Webfonts/AHAMONO-Regular.eot'),
  url('../font/AHAMONO-Regular/Webfonts/AHAMONO-Regular.woff'),
  url('../font/AHAMONO-Regular/Webfonts/AHAMONO-Regular.woff2')
}

@font-face {
  -webkit-font-smoothing: antialiased;
  font-family: 'Monaco';
  src: url('../font/Monaco.otf');
}
