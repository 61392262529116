@keyframes scaleDown {
  from {
    transform: scale(1.5)
  }
  to {
    transform: scale(1)
  }
}

@keyframes scaleDown-mobile {
  from {
    transform: scale(2.5)
  }
  to {
    transform: scale(1)
  }
}

.app-container {
  &.web {
    .particles-container {
      margin-bottom: 88px;
    }
  }

  &.tablet {
    .particles-container {
      margin-bottom: 65px;
    }
  }

  &.mobile {
    .particles-container {
      margin-bottom: 20px;
    }
  }

}

.particles-container {
  position: relative;
  max-width: 100vw;
  overflow: hidden;
}

.particles-canvas {
  top: 0;
  position: absolute;
  z-index: 5;
  overflow: hidden;
  animation : scaleDown 3.5s ease-out forwards;

  &.mobile {
    animation : scaleDown-mobile 3.5s ease-out forwards
  }
}

