.hero-banner-container {
  position: relative;
  overflow: hidden;
  background: linear-gradient(19deg, #00daed 0%, #6c00ff 75%);

  &.mobile {
    height: 360px;
    // margin-top: 38px;
  }

  &.tablet {
    height: 449px;
    // margin-top: 38px;
  }

  &.web {
    height: 805px;
  }

  .row {
    position: relative;
    display: -webkit-flex;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 calc(50vw - 34vw - 40px);
    height: 100%;

    &.mobile {
      padding: 0 calc(50vw - 32vw - 40px);
      top: -20%;
    }

    &.tablet {
      padding: 0 calc(50vw - 36vw - 40px);
    }

    &.web {
      padding: 0 10vw;
      margin: 0;
      justify-content: space-between;
    }
  }

  .low-layer {
    font-family: section;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffff0b;
    -webkit-font-smoothing: antialiased;

    &.mobile {
      font-size: 28vw;
    }

    &.tablet {
      opacity: 0.7;
      font-size: 210px;
      font-size: 25vw;
    }

    &.web {
      font-size: 24vw;
    }

    &.char-h {
      opacity: .7;
      mix-blend-mode: overlay;
      transform: translateY(-24%);

      span {
        display: block;
        transform: scale(0.8);
        opacity: 0;
        -webkit-animation : hello-grow 1s ease-out forwards;
        animation : hello-grow 1s ease-out forwards;
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
      }

      &.mobile {
        opacity: .5;
      }
    }

    &.char-e {
      padding-left: 0;
      opacity: .7;
      mix-blend-mode: overlay;
      transform: translateY(0%);

      span {
        display: block;
        transform: scale(0.8);
        opacity: 0;
        -webkit-animation : hello-grow 1s ease-out forwards;
        animation : hello-grow 1s ease-out forwards;
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
      }

      &.mobile {
        opacity: .5;
      }
    }

    &.char-l {
      padding-left: 0;
      mix-blend-mode: difference;
      transform: translateY(-20%);

      span {
        display: block;
        transform: scale(0.8);
        opacity: 0;
        -webkit-animation : hello-grow 1s ease-out forwards;
        animation : hello-grow 1s ease-out forwards;
        -webkit-animation-delay: 0.8s;
        animation-delay: 0.8s;
      }

      &.mobile {
        opacity: .4;
      }
    }

    &.char-ll {
      opacity: .4;
      mix-blend-mode: overlay;
      transform: translateY(-33%);

      span {
        display: block;
        transform: scale(0.8);
        opacity: 0;
        -webkit-animation : hello-grow 1s ease-out forwards;
        animation : hello-grow 1s ease-out forwards;
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
      }

      &.mobile {
        opacity: .3;
      }
    }

    &.char-o {
      opacity: .4;
      mix-blend-mode: overlay;
      transform: translateY(-20%);

      span {
        display: block;
        transform: scale(0.8);
        opacity: 0;
        -webkit-animation : hello-grow 1s ease-out forwards;
        animation : hello-grow 1s ease-out forwards;
        -webkit-animation-delay: 1.2s;
        animation-delay: 1.2s;
      }

      &.mobile {
        opacity: .3;
      }
    }
  }

  .banner-locations {
    transform : rotate(90deg);
    transform-origin: top left;
    position  : absolute;
    color     : white;
    word-break: break-all;
    width     : 100%;
    max-width : 400px;
    height    : 15px;
    // overflow  : hidden;

    .banner-location-item {
      display: inline-block;

      .tween-one {

        span {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }

    &.mobile {
      top      : 10%;
      left     : 7%;
      font-size: 10px;
      // animation : locations-expand-mobile 10s linear infinite;
    }

    &.tablet {
      font-size : 11px;
      top       : 10%;
      left      : 5%;
      // animation : locations-expand-tablet 10s linear infinite;
    }

    &.web {
      font-size : 12px;
      top       : 10%;
      left      : 5%;
      // animation : locations-expand 15s linear infinite;
    }
  }

  .hero-banner-title {
    width     : 100%;
    color       : white;
    position    : absolute;
    -webkit-font-smoothing: antialiased;
    opacity: 0;
    text-align: center;
    white-space: nowrap;

    &.mobile {
      font-size : 45px;
      top       : 30%;
      left        : calc(50% - 175px);
      -webkit-animation : slideTitle-mobile 1s ease-out forwards;
      animation : slideTitle-mobile 1s ease-out forwards;
      -webkit-animation-delay: 1.2s;
      animation-delay: 1.2s;
    }

    &.tablet {
      top         : 34%;
      font-size   : 62px;
      width: inherit;
      left        : calc(50% - 120px);
      -webkit-animation : slideTitle-tablet 1s ease-out forwards;
      animation : slideTitle-tablet 1s ease-out forwards;
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
    }

    &.web {
      font-size : 135px;
      top       : calc(50% - 135px);
      -webkit-animation : slideTitle 1s ease-out forwards;
      animation : slideTitle 1s ease-out forwards;
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
      margin-bottom: 0;
    }
  }

  .hero-banner-description {
    color       : white;
    position    : absolute;
    top         : 60%;
    left        : 45%;
    width       : 50%;
    opacity     : 0;
    -webkit-animation   : slideDescription 1.2s ease-out .4s forwards;
    animation   : slideDescription 1.2s ease-out .4s forwards;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 0;

    &.mobile {
      // min-width   : 185px;
      font-size   : 14px;
      top         : 50%;
      left: auto;
      right       : -100%;
      width       : calc(60% - 10px);
      -webkit-animation   : slideDescription-mobile 1.2s ease-out .4s forwards;
      animation   : slideDescription-mobile 1.2s ease-out .4s forwards;
    }

    &.tablet {
      width     : 50%;
      font-size : 16px;
      top       : calc(34% + 90px);
      -webkit-animation   : slideDescription-tablet 1.2s ease-out .4s forwards;
      animation   : slideDescription-tablet 1.2s ease-out .4s forwards;
    }

    &.web {
      min-width: 50vw;
      font-size : 25px;
      top: 54%;
      left: 45%;
    }
  }

  @media (max-width: 360px) {
    .banner-locations {
      &.mobile {
        font-size: 9px;
        top: 15%;
      }
    }

    .hero-banner-title {
      &.mobile {
        top: 25%;
      }
    }

    .hero-banner-description {
      &.mobile {
        min-width : 170px;
        top       : 55%;
        right     : 20px;
      }
    }
  }
}

// tablet
// @media (min-width: 1024px) {
@media (min-width: 1200px) {
  .hero-banner-container {
    &.tablet {
      height: 480px;
      margin-top: 50px;
    }
  }

  &.tablet {
    .banner-locations {
      &.tablet {
        font-size: 12px;
        top: 15%;
      }
    }
  }

  &.tablet {
    &.hero-banner-title {
      &.tablet {
        top: 45%;
      }
    }
    &.hero-banner-description {
      top: 65%;
    }
  }
}


// Keyframes

@keyframes slideTitle-mobile {
  from {
    left    : 100%;
    opacity : 0.1;
  }
  to {
    left    : 0;
    opacity : 1;
  }
}

@keyframes slideTitle {
  from {
    left    : 100%;
    opacity : 0.1;
  }
  to {
    left    : 0;
    opacity : 1;
  }
}

@keyframes slideTitle-tablet {
  from {
    left    : 100%;
    opacity : 0.1;
  }
  to {
    left    : calc(40% - 175px);
    opacity : 1;
  }
}

@keyframes slideDescription {
  from {
    left    : 100%;
    opacity : 0.1;
  }
  to {
    left: calc(50% - 224px);
    opacity : 1;
  }
}

@keyframes slideDescription-mobile {
  from {
    right    : -100%;
    opacity : 0.1;
  }
  to {
    right: 16px;
    opacity : 1;
  }
}

@keyframes slideDescription-tablet {
  from {
    left    : 100%;
    opacity : 0.1;
  }
  to {
    left: calc(40% - 16px);
    opacity : 1;
  }
}

@keyframes hello-grow {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes background-slide-up {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@keyframes locations-expand-mobile {
  from {
    width: 0px;
  }
  to {
    width: 300px;
  }
}

@keyframes locations-expand-tablet {
  from {
    width: 0px;
  }
  to {
    width: 350px;
  }
}

@keyframes locations-expand {
  from {
    width: 0px;
  }
  to {
    width: 400px;
  }
}
