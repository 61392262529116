.view-projects {
  $project-transition: 0.75s;
  $project-transition-style: cubic-bezier(.25, 0, .5, 1);

  .container {
    background-color: black;
    display: flex;

    span {
      -webkit-transition: all $project-transition $project-transition-style;
      transition: all $project-transition $project-transition-style;
      color: white;
      text-transform: uppercase;

      &.rotated {
        transform: rotate(90deg);
      }
    }
  }

  &.web {
    .container {
      padding: calc(4vw / 17) calc(17vw / 17) calc(4vw / 17) calc(21vw / 17);

      span {
        font-size: calc(20vw / 17);
        letter-spacing: calc(1vw / 17);
        line-height: calc(29vw / 17);

        &:last-child {
          margin-top: -2px;

          &.rotated {
            margin-top: 0;
          }
        }
      }
    }
  }

  &.tablet {
    .container {
      padding: 4px 17px 4px 21px;

      span {
        font-size: 11.2px;
        letter-spacing: 0.56px;
        line-height: 16.24px;

        &:last-child {
          margin-top: -1px;

          &.rotated {
            margin-top: 0;
          }
        }
      }
    }
  }

  &.mobile {
    .container {
      padding: 4px 17px 4px 21px;

      span {
        font-size: 11.2px;
        letter-spacing: 0.56px;
        line-height: 16.24px;
      }
    }
  }




  // &.mobile {
  //   .read-more-link {
  //     &.turned {
  //       bottom: -193px;
  //     }

  //     .vertical-line {
  //       &.shadow-lines {
  //         .shader {
  //           top: -2.5px;
  //           height: calc(#{$cross-size-mobile} + 3px);
  //           width: $cross-width-mobile;
  //         }
  //       }
  //     }

  //     .top-lines {
  //       .shader {
  //         height: $cross-size-mobile;
  //       }
  //     }

  //     .shadow-lines {
  //       height: $cross-size-mobile;


  //       .shader {
  //         height: calc(#{$cross-size-mobile} + 0px);
  //         width: $cross-width-mobile;
  //         // background-color: red;
  //       }
  //     }

  //     &.turned {

  //       .vertical-line.shadow-lines {
  //         .shader {
  //           top: -3.5px;
  //           height: $cross-size-mobile;
  //           width: $cross-width-mobile;
  //           // background-color: red;
  //         }
  //       }

  //       .flat-line.shadow-lines {
  //         .shader {
  //           top: 3.5px;
  //           height: $cross-size-mobile;
  //           width: $cross-width-mobile;
  //           // background-color: red;
  //         }
  //       }
  //     }
  //   }
  // }

  // &.web {
  //   .read-more-link {
  //     &.turned {
  //       bottom: -81px;
  //     }
  //   }
  // }

  // &.tablet {
  //   .read-more-link {
  //     &.turned {
  //       bottom: -35px;
  //     }

  //     .vertical-line {
  //       &.shadow-lines {
  //         .shader {
  //           height: calc(#{$cross-size-tablet} + 5px);
  //           width: 6px;
  //         }
  //       }
  //     }

  //     .top-lines {
  //       .shader {
  //         height: $cross-size-tablet;
  //       }
  //     }

  //     .shadow-lines {
  //       height: $cross-size-tablet;

  //       .shader {
  //         height: calc(#{$cross-size-tablet} + 2px);
  //         width: 6px;
  //       }
  //     }

  //     &.turned {

  //       .vertical-line.shadow-lines {
  //         .shader {
  //           height: $cross-size-tablet;
  //           width: 6px;
  //         }
  //       }

  //       .flat-line.shadow-lines {
  //         .shader {
  //           top: 4.5px;
  //           height: $cross-size-tablet;
  //           width: 6px;
  //         }
  //       }
  //     }
  //   }
  // }

  // .read-more-link {
  //   left: -1px;
  //   position: absolute;
  //   bottom: 60px;
  //   width: 40px;
  //   height: 40px;
  //   padding-left: 16px;

  //   -webkit-transition: all $project-transition $project-transition-style;
  //   transition: all $project-transition $project-transition-style;

  //   $shadow-thickness: 9px;

  //   .cross-lines {
  //     position: absolute;
  //     transform-origin: bottom;

  //     -webkit-transition: all $project-transition $project-transition-style;
  //     transition: all $project-transition $project-transition-style;

  //     &.turned {
  //       transform-origin: center;
  //     }

  //     .shader {
  //       position: relative;
  //       -webkit-transition: all $project-transition $project-transition-style;
  //       transition: all $project-transition $project-transition-style;
  //     }
  //   }

  //   $vertical-rotation: 45deg;
  //   $vertical-rotation-turned: 450deg;

  //   .vertical-line {
  //     -webkit-transform: rotate($vertical-rotation);
  //     transform: rotate($vertical-rotation);

  //     &.turned {
  //       -webkit-transform: rotate($vertical-rotation-turned);
  //       transform: rotate($vertical-rotation-turned);
  //     }

  //     &.shadow-lines {
  //       .shader {
  //         top: -4.5px;
  //         height: calc(#{$cross-size-web} + 5px);
  //         // -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 96%);
  //         // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 96%);
  //       }
  //     }
  //   }

  //   $flat-rotation: -45deg;
  //   $flat-rotation-turned: -360deg;

  //   .flat-line {
  //     -webkit-transform: rotate($flat-rotation);
  //     transform: rotate($flat-rotation);

  //     &.turned {
  //       -webkit-transform: rotate($flat-rotation-turned);
  //       transform: rotate($flat-rotation-turned);
  //     }
  //   }

  //   .top-lines {
  //     .shader {
  //       background-color: black;
  //       bottom: -1px;
  //       height: $cross-size-web;
  //       width: 2px;
  //     }
  //   }

  //   .shadow-lines {
  //     height: $cross-size-web;
  //     width: 2px;
  //     overflow: inherit;

  //     .shader {
  //       background-color: #FFF800;
  //       bottom: -$shadow-thickness/2;
  //       left: 2px;
  //       height: calc(#{$cross-size-web} + 3px);
  //       width: $shadow-thickness;
  //       // -webkit-clip-path: polygon(0 0, 100% 0, 100% 96%, 0 100%);
  //       // clip-path: polygon(0 0, 100% 0, 100% 96%, 0 100%);
  //     }
  //   }

  //   &.turned {
  //     bottom: -148px;

  //     .vertical-line.shadow-lines {
  //       .shader {
  //         top: -$shadow-thickness/2 - 1px;
  //         height: $cross-size-web;
  //         // -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  //         // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  //         left: 2px;
  //       }
  //     }

  //     .flat-line.shadow-lines {
  //       .shader {
  //         top: $shadow-thickness/2 + 2px;
  //         height: $cross-size-web;
  //         // -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  //         // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  //       }
  //     }
  //   }
  // }
}
