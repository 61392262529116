.react-multi-carousel-item {
  .image {
    width: 100%;
    padding-right: 46px;
  }

  &:last-child {
    .image {
      padding-right: 0px;
    }
  }

  .image-caption {
    margin-top: 10px;
    font-size : 10px;
  }

  // mobile
  //@media (max-width: 420px) {
  @media (max-width: 480px) {
    .image {
      padding: 0;
    }
    .image-caption {
      margin-left: 40px;
    }
  }

  // tablet
  // @media (min-width: 1024px) {
  @media (min-width: 1200px) {
    .image-caption {
      font-size: 14px;
      margin-top: 14px;
    }
  }
}

.carousel-container {
  .react-multi-carousel-list {
    overflow: inherit;
  }

  .react-multiple-carousel__arrow,
  .react-multiple-carousel__arrow:hover {
    background: transparent;
    top       : 35%;
    z-index   : 1;
  }

  .image {
    max-height: 52.45vw;
  }

  &.web,
  &.tablet {
    .react-multiple-carousel__arrow {
      display: none;
    }
  }

  .react-multi-carousel-dot {
    button {
      background-color: white;
      border: 0;
      width: 9px;
      height: 9px;
      margin-right: 9px;
    }
  }

  .react-multi-carousel-dot--active {
    button {
      background-color: black;
    }
  }

  .react-multi-carousel-dot-list {
    bottom: -38px;
  }
}

.drag-bar {
  position  : absolute;
  top    : calc(50% - 114px + 16px);;
  left      : 33%;
  width     : 33%;
  background: yellow;
  text-align: center;
  font-size : 14px;
  pointer-events: none;
  transition: 1s;
  display   : flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding-left: 9px;
  padding-right: 9px;


  .line {
    border-top: 1px solid black;
    margin: 0 5px;

    &.left {
      flex: 2;
      margin-right: 10px;
    }

    &.right {
      flex: 1;
      margin-left: 10px;
    }
  }
}

.invisible {
  opacity: 0;
}

