.characteristic-container {
  width           : 85%;
  margin          : auto;
  display         : flex;
  flex-wrap       : wrap;
  justify-content : space-between;

  .characteristic-item {
    flex-basis    : 25%;
    text-align    : center;
    align-items   : center;
    display       : flex;
    flex-direction: column;
    margin-bottom : 20px;
    z-index: 15;

    .characteristic-icon {
      height: 40px;
    }

    .icon-name {
      white-space   : nowrap;
      margin-top    : 5px;
      text-transform: uppercase;
      -webkit-font-smoothing: antialiased;
    }
  }

  &.mobile {
    padding-top      : 25px;
    padding-bottom   : 35px;

    .characteristic-item {
      .characteristic-icon {
        height: 30px;
      }

      .icon-name {
        margin-top: 10px;
        font-size: 9.5px;
      }

      &:nth-child(4) {
        margin-left: 20%;
      }

      &:nth-child(5) {
        margin-right: 20%;
      }

      &:nth-child(n+5) {
        margin-bottom: 0;
      }
    }
  }

  &.tablet {
    padding-top      : 38px;
    padding-bottom   : 26px;

    .characteristic-item {
      margin-bottom: 17px;

      &:nth-child(n+6) {
        margin-bottom: 0;
      }

      .icon-name {
        margin-top: 9px;
        font-size: 13px;
      }
    }
  }

  &.web {
    padding-top      : 45px;
    padding-bottom   : 63px;

    .characteristic-item {
      flex-basis: 0;
      margin-bottom: 0;

      .characteristic-icon {
        height: 58px;
      }

      .icon-name {
        margin-top: 13px;
        font-size: 13px;
      }
    }
  }
}
